import { IS_DEPLOYMENT_MODE } from "../constants/env";
import { useHubspotChat } from "../featureFlags";

import { HubSpotChatClass } from "./hubspot";

// Create a singleton instance of the HubSpotChat class
const HubspotChat = new HubSpotChatClass();

export function useChatIntegration({ chatInsertedRef, userEmail }) {
  const chatEnabled = IS_DEPLOYMENT_MODE && !window.location.pathname.includes("/guest/");

  // Are we using Hubspot chat, or Intercom?
  const hubspotChatEnabled = useHubspotChat();

  // Called from App.js to insert chat scripts
  const insertChat = () => {
    if (!chatEnabled) return;

    if (hubspotChatEnabled) {
      if (!chatInsertedRef.current) {
        // Load HubSpot chat
        HubspotChat.initialize(userEmail);
      } else {
        // Update HubSpot chat with new email
        HubspotChat.setEmail(userEmail);
      }
    } else if (!chatInsertedRef.current) {
      // Load Intercom chat
      runIntercomScript();
    }

    chatInsertedRef.current = true;
  };

  return { insertChat };
}

// Load the Intercom chat widget
const runIntercomScript = () => {
  const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
  window.intercomSettings = { app_id: APP_ID };
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
};
