import React, { Suspense, useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import T from "prop-types";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";

import { getIsSjAdminFromStorage, shouldBypassTenantSelection } from "./helpers/user";
import { retrieveAuthenticatedUserInfo } from "./helpers/authService";
import { getPathForRedirect, removePathForRedirect } from "./helpers/redirectHelpers";
import { useChatIntegration } from "./helpers/chatIntegration";
import EmptyPageLoader from "./components/EmptyPageLoader";
import ChooseTenantModal from "./user/components/common/modals/ChooseTenantModal";
import {
  HE_LOGIN_PATH,
  HE_SJ_ADMIN_LOGIN_PATH,
  SAML_LOGIN_PATH,
  SAML_SJ_ADMIN_LOGIN_PATH,
  SJ_ADMIN_SIGN_IN_PATH,
} from "./routes/landing";
import { ADMIN_BASE } from "./admin/routes";
import HeRedirectApp from "./HeRedirectApp";
import { useTrackTime } from "./hooks/useTrackTime";
import {
  LESSON_PATH,
  ASSESSMENT_PATH,
  EXPERIMENT_PATH,
  MISSION_EXPERIMENT_PATH,
  CATALOG_LESSON_PATH,
  CATALOG_EXPERIMENT_PATH,
  CATALOG_ASSESSMENT_PATH,
} from "./constants/trackablePaths";
import FullScreenPreloader from "./components/FullScreenPreloader";
import { NetworkError } from "./components/NetworkError/NetworkError";
import { datadogRum } from "@datadog/browser-rum";
import { runSegmentIo } from "./helpers/segmentIo";
import { Toast } from "primereact/toast";
import { ToastProvider } from "./contexts/ToastContext";
import { buildLdContext } from "./helpers/launchDarkly";

const FrontendApp = React.lazy(() => import("./FrontendApp"));
const AdminFrontendApp = React.lazy(() => import("./AdminFrontendApp"));
const SjAdminApp = React.lazy(() => import("./sjadmin/SjAdminApp"));
const LandingApp = React.lazy(() => import("./landing/LandingApp"));

const REDIRECT_PATHS = [
  SAML_SJ_ADMIN_LOGIN_PATH,
  SAML_LOGIN_PATH,
  HE_LOGIN_PATH,
  HE_SJ_ADMIN_LOGIN_PATH,
];

const App = ({
  isAuthenticated,
  isVariablesLoaded,
  loadAsyncVariables,
  isNetworkError,
  hasAdminAccess,
  isSiteLoaded,
  currentTenantName,
  accessType,
}) => {
  useTrackTime({
    paths: [
      LESSON_PATH,
      ASSESSMENT_PATH,
      EXPERIMENT_PATH,
      MISSION_EXPERIMENT_PATH,
      CATALOG_LESSON_PATH,
      CATALOG_ASSESSMENT_PATH,
      CATALOG_EXPERIMENT_PATH,
    ],
  });
  const [isOpenChooseTenantModal, setIsOpenChooseTenantModal] = useState(false);
  const [isUserIdTo3rdParties, setIsUserIdTo3rdParties] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useRef(null);
  const isSjAdmin = getIsSjAdminFromStorage();
  const hasSelectedTenant = !!currentTenantName;

  // Figure out if user is identified, either from an auth token or from loading user info from Rails
  const tokenUserInfo = retrieveAuthenticatedUserInfo();
  const isUserIdentified = !!tokenUserInfo;

  // Insert the chat
  const chatInsertedRef = useRef(false);
  const { insertChat } = useChatIntegration({ chatInsertedRef, userEmail: tokenUserInfo?.email });

  useEffect(() => {
    insertChat();
  }, [tokenUserInfo]);

  // Intercom
  // SegmentIO
  useEffect(() => {
    runSegmentIo();
    loadAsyncVariables();
  }, [loadAsyncVariables]);

  // Launch Darkly Client
  const ldClient = useLDClient();

  // Identify user to 3rd party services
  useEffect(() => {
    const init3rdPartyUser = async () => {
      datadogRum.setUser({
        id: tokenUserInfo.userId,
        email: tokenUserInfo.email,
        tenant: currentTenantName,
      });

      await ldClient.identify(buildLdContext(tokenUserInfo, currentTenantName));

      setIsUserIdTo3rdParties(true);
    };

    if (isUserIdentified && !isUserIdTo3rdParties) {
      init3rdPartyUser();
    }
  }, [isUserIdentified]);

  // Add tenant to datadog user when selected
  useEffect(() => {
    const updateLdContext = async () => {
      await ldClient.identify(buildLdContext(tokenUserInfo, currentTenantName));
    };

    if (currentTenantName) {
      const ddRumContext = datadogRum.getUser();
      if (ddRumContext) {
        datadogRum.setUserProperty("tenant", currentTenantName);
      }

      updateLdContext();
    }
  }, [currentTenantName]);

  // Other info
  const isRedirectAppUrl = REDIRECT_PATHS.includes(location.pathname);
  const bypassTenantSelection = shouldBypassTenantSelection();

  // Before rendering, figure out if we need to redirect (happens after login & site variable load)
  if (isAuthenticated && isSiteLoaded && !isSjAdmin) {
    const pathForRedirect = getPathForRedirect();
    if (pathForRedirect) {
      removePathForRedirect();
      if (hasAdminAccess) {
        // admins get redirected to /admin by default
        pathForRedirect === "/" ? navigate(ADMIN_BASE) : navigate(pathForRedirect);
      } else {
        if (pathForRedirect.startsWith("/scorm")) {
          window.location.href = pathForRedirect;
        } else {
          navigate(pathForRedirect);
        }
      }
    }
  }

  // The various routes which can be taken
  // Anything in the memo list will could cause the app to reload
  return useMemo(() => {
    if (isNetworkError) return <NetworkError />;

    if (!isVariablesLoaded) return <FullScreenPreloader />;

    if (isRedirectAppUrl) {
      return (
        <Routes>
          <Route path={SAML_SJ_ADMIN_LOGIN_PATH} element={<HeRedirectApp mode="samlSjAdmin" />} />
          <Route path={SAML_LOGIN_PATH} element={<HeRedirectApp mode="samlUser" />} />
          <Route path={HE_LOGIN_PATH} element={<HeRedirectApp mode="user" />} />
          <Route path={HE_SJ_ADMIN_LOGIN_PATH} element={<HeRedirectApp mode="sjAdmin" />} />
        </Routes>
      );
    }

    if (
      isAuthenticated &&
      !isSjAdmin &&
      !hasSelectedTenant &&
      !bypassTenantSelection &&
      !accessType.lessonOnly
    ) {
      setIsOpenChooseTenantModal(true);
    } else {
      setIsOpenChooseTenantModal(false);
    }

    // Ensure LD user is initialized before 1st authenticated render
    if (isAuthenticated && !isUserIdTo3rdParties) {
      <FullScreenPreloader />;
    }

    return (
      <ToastProvider toastRef={toast}>
        <Toast ref={toast} position="bottom-right" />
        <Suspense fallback={<EmptyPageLoader />}>
          {isOpenChooseTenantModal && (
            <ChooseTenantModal
              isOpen={isOpenChooseTenantModal}
              setIsOpen={setIsOpenChooseTenantModal}
            />
          )}
          <>
            {!isOpenChooseTenantModal && (
              <>
                {isAuthenticated && (
                  <Routes>
                    <Route path={`${ADMIN_BASE}/*`} element={<AdminFrontendApp />} />
                    <Route path="/*" element={<FrontendApp />} />
                    <Route path="/guest/*" element={<FrontendApp />} />
                    <Route path="/scorm/*" element={<FrontendApp />} />
                  </Routes>
                )}

                {!isAuthenticated && (
                  <Routes>
                    <Route path={SJ_ADMIN_SIGN_IN_PATH} element={<SjAdminApp />} />
                    <Route path="/*" element={<LandingApp />} />
                    <Route path="/guest/*" element={<FrontendApp />} />
                    <Route path="/scorm/*" element={<FrontendApp />} />
                  </Routes>
                )}
              </>
            )}
          </>
        </Suspense>
      </ToastProvider>
    );
  }, [
    isAuthenticated,
    isVariablesLoaded,
    isRedirectAppUrl,
    isOpenChooseTenantModal,
    isNetworkError,
  ]);
};

App.propTypes = {
  isAuthenticated: T.bool.isRequired,
  userId: T.number,
  email: T.string,
  name: T.string,
  trialMode: T.bool.isRequired,
  isLoaded: T.bool,
  loadAsyncVariables: T.func.isRequired,
  isVariablesLoaded: T.bool.isRequired,
  isNetworkError: T.bool.isRequired,
  isSiteLoaded: T.bool,
  hasAdminAccess: T.bool.isRequired,
  currentTenantName: T.string,
  accessType: T.object,
};

const mapState = ({
  user: { id: userId, isAuthenticated, isLoaded, email, name, accessType },
  site: { trialMode, isNetworkError, hasAdminAccess, isLoaded: isSiteLoaded, currentTenantName },
  variables: { isVariablesLoaded },
}) => ({
  isAuthenticated,
  trialMode,
  userId,
  isLoaded,
  email,
  name,
  isVariablesLoaded,
  isNetworkError,
  currentTenantName,
  isSiteLoaded,
  hasAdminAccess,
  accessType,
});

const mapDispatch = ({ variables: { loadAsyncVariables } }) => ({ loadAsyncVariables });

export default connect(mapState, mapDispatch)(App);
