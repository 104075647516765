import { getDirectoryClient } from "./serviceClients/directoryService";

const twelveHoursMinusOneMinute = 1000 * 60 * 60 * 12 - 60000; // 12 hours minus 1 minute

// Singleton class/Object
export class HubSpotChatClass {
  constructor() {
    this.widget_status = "not_loaded";
    this.API = null;
    this.email = null;
    this.tokenStatus = "ready";
    this.token = null;
    this.widget_user_id = false;
    this.loadedCallback = null;
  }

  initialize(userEmail) {
    // If user email is provided, set it to start retrieving the HubSpot ID token
    this.setEmail(userEmail);

    // Insert the hubspot chat script
    insertHubspotChat(() => {
      this.onConversationsAPIReady();
    });
  }

  setEmail(email) {
    if (email && email !== this.email) {
      this.email = email;
      this.retrieveHubspotIdToken();

      // Set a timeout to refresh the token every 12 hours
      setTimeout(() => {
        this.retrieveHubspotIdToken();
      }, twelveHoursMinusOneMinute);
    }
  }

  setToken(token) {
    this.token = token;
    this.updateWindowSettings();
    this.tokenStatus = "ready";
  }

  onConversationsAPIReady() {
    if (window.HubSpotConversations !== this.API) {
      if (this.API) {
        this.API.off("widgetLoaded", this.loadedCallback);
      }

      this.API = window.HubSpotConversations;
      this.loadedCallback = this.onWidgetLoaded.bind(this);
      this.API.on("widgetLoaded", this.loadedCallback);

      // Call the function to load the HubSpot widget
      this.loadHubspotWidget();
    }
  }

  onWidgetLoaded() {
    console.log("HubSpot widget loaded");
    const old_status = this.widget_status;
    this.widget_status = "loaded";
    if (old_status === "pending") {
      this.updateWindowSettings();
      this.reloadHubspotWidget();
    }
  }

  updateWindowSettings() {
    if (this.email && this.token) {
      if (
        window.hsConversationsSettings.identificationEmail !== this.email &&
        window.hsConversationsSettings.identificationToken !== this.token
      ) {
        window.hsConversationsSettings = {
          loadImmediately: false,
          identificationEmail: this.email,
          identificationToken: this.token,
        };
      }
    }
  }

  async retrieveHubspotIdToken() {
    this.tokenStatus = "loading";
    const client = getDirectoryClient();
    const { data, error } = await client.hubspotId();
    if (error) {
      console.error("Error retrieving HubSpot ID token:", error);
      this.tokenStatus = "error";
    }
    if (!data) {
      console.error("No HubSpot ID token found.");
      this.tokenStatus = "error";
    }

    this.setToken(data.token);

    // Once the token is retrieved, load the HubSpot widget
    this.loadHubspotWidget();
  }

  loadHubspotWidget() {
    if (!this.API || this.tokenStatus !== "ready") {
      return;
    }

    // Check the current status of the HubSpot widget
    const status = this.API.widget.status();

    if (!status.loaded && !status.pending) {
      // If the email and token are available, set them in the hsConversationsSettings
      // This will identify the user in the HubSpot chat
      this.widget_status = "loading";
      this.API.widget.load();
    }

    if (status.loaded) {
      this.reloadHubspotWidget();
    }

    if (!status.loaded && status.pending) {
      this.widget_status = "pending";
    }
  }

  reloadHubspotWidget() {
    this.widget_status = "loading";
    this.API.clear({ resetWidget: true });
  }
}

// Insert the HubSpot chat script into the page
const insertHubspotChat = onConversationsAPIReady => {
  console.log("Inserting HubSpot chat script");
  /*
      configure window.hsConversationsSettings prior to adding the script
      */
  window.hsConversationsSettings = {
    loadImmediately: false,
  };

  /* configure callback */
  window.hsConversationsOnReady = [onConversationsAPIReady];

  // Add script tag to load the HubSpot Conversations API
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "hs-script-loader";
  script.async = true;
  script.defer = true;
  script.src = "//js.hs-scripts.com/6720694.js";

  document.body.appendChild(script);
};
